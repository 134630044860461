import {Box, CardMedia, Paper, Typography} from "@mui/material";
import img from "../images/aboutMe/portraet.jpg";
import translations from '../translations.json';
import {Helmet} from 'react-helmet-async';

export const AboutMe = () => {
  return (
      <Box>
        <Helmet>
          <title>{translations.aboutMePage.pageTitle}</title>
          <meta name="description" content={translations.aboutMePage.description}/>
          <meta property="og:title" content={translations.aboutMePage.pageTitle}/>
          <meta property="og:description" content={translations.aboutMePage.description}/>
          <meta property="og:url" content="https://www.frei-arbeit.ch/me"/>
          <meta property="og:type" content="website"/>
          <link rel="canonical" href="https://www.frei-arbeit.ch/me"/>
        </Helmet>
        <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            flexWrap="nowrap"
            gap="2rem"
            justifyContent="center"
            alignItems="center"
            height={{ xs: "unset", md: "80vh" }}
            marginLeft={{ xs: "0", md: "2rem" }}
            marginRight={{ xs: "0", md: "2rem" }}
            marginTop={{ xs: "2rem", md: "0" }}
            marginBottom={{ xs: "2rem", md: "0" }}
        >
          <Paper
              sx={{
                height: {md: "80%", xs: "unset"},
                backgroundColor: "#a9927d",
                width: { xs: "80%", md: "unset" }
              }}
          >
            <CardMedia
                component="img"
                image={img}
                alt="alt"
                sx={{ height: { xs: "unset", md: "100%" }, width: { xs: "100%", md: "unset" } }}
            />
          </Paper>
          <Paper
              sx={{
                height: "80%",
                backgroundColor: "#a9927d",
                overflow: "auto",
                width: { xs: "80%", md: "80%" },
                '&::-webkit-scrollbar': {
                  width: '10px',
                  display: 'block', // make scrollbar always visible
                },
                '&::-webkit-scrollbar-track': {
                  background: '#a9927d',
                  borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#8B7B6A',
                  borderRadius: '10px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#72664B',
                },
                WebkitOverflowScrolling: 'touch',
              }}
          >
            <Box padding="1rem">
              <h1>{translations.aboutMePage.pageTitle}</h1>
              <div>
                {translations.aboutMePage.details.map((detail: {key: string, value: string}, index: number) => (
                    <Typography component="div" key={index}>
                      <Typography fontWeight="bold">{detail.key}: </Typography>
                      {detail.key === "Email" ? (
                          <a href={detail.value}>{detail.value.replace("mailto:", "")}</a>
                      ) : (
                          <Typography component="span">{detail.value}</Typography>
                      )}
                    </Typography>
                ))}
              </div>
            </Box>
          </Paper>
        </Box>
      </Box>
  )
}

export default AboutMe;