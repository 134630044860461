// src/assets/images/index.ts

const importAll = (requireContext: __WebpackModuleApi.RequireContext): Record<string, string> => {
    const files: Record<string, string> = {};
    requireContext.keys().forEach((item) => {
        files[item.replace('./', '')] = requireContext(item).default;
    });
    return files;
};

// Import all images from the 'images' directory and its subdirectories
const images = importAll(require.context('/public/images/gallery', true, /\.(png|jpe?g|svg)$/));

export default images;
