import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    CircularProgress,
    createTheme,
    MenuItem,
    TextField,
    ThemeProvider,
    Typography
} from "@mui/material";
import translations from "../../translations.json";
import {useLocation} from "react-router-dom";
import emailjs from '@emailjs/browser';
import './Contact.css';
import {useSnackbar} from "notistack";
import {track} from "@vercel/analytics";

const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const EMAILJS_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
const EMAILJS_CONFIRMATION_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_CONFIRMATION_TEMPLATE_ID;
const EMAILJS_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

export const Contact = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const courseId = queryParams.get('course');
    const [course, setCourse] = useState<string>(courseId ? courseId : translations.contactPage.selectDefaultValue);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const {enqueueSnackbar} = useSnackbar();
    const [envLoaded, setEnvLoaded] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false); // New state variable

    useEffect(() => {
        if (EMAILJS_SERVICE_ID && EMAILJS_TEMPLATE_ID && EMAILJS_CONFIRMATION_TEMPLATE_ID && EMAILJS_PUBLIC_KEY) {
            setEnvLoaded(true);
        }
    }, []);

    const theme = createTheme({
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '& .MuiInputBase-input': {
                            color: 'white', // Text color
                        },
                        '& .MuiInputLabel-root': {
                            color: 'white', // Label color
                        },
                        '& .Mui-focused .MuiInputLabel-root': {
                            color: 'white', // Label color when focused
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'white', // Default border color
                            },
                            '&:hover fieldset': {
                                borderColor: 'white', // Border color when hovered
                                borderWidth: '2px', // Slightly thicker border on hover
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'white', // Border color when focused
                                borderWidth: '2px', // Slightly thicker border on focus
                            },
                        },
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        '&.Mui-focused': {
                            color: 'white', // Ensure label stays white when focused
                        },
                    },
                },
            },
        },
    });

    function sendConfirmation() {
        emailjs.send(EMAILJS_SERVICE_ID!, EMAILJS_CONFIRMATION_TEMPLATE_ID!, {
            to_name: name,
            to_email: email,
        }, EMAILJS_PUBLIC_KEY).then((result) => {
            track('Confirmation Email Sent', {course: course});
            enqueueSnackbar('Confirmation email sent', {variant: 'success'});
            //reset form
            setCourse(courseId ? courseId : translations.contactPage.selectDefaultValue);
            setName('');
            setEmail('');
            setMessage('');
        }, (error) => {
            track('Confirmation Email Error', {course: course});
            enqueueSnackbar('Error sending confirmation email', {variant: 'error'});
        }).finally(() => {
            setIsSubmitting(false); // Re-enable the button
        });
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true); // Disable the button
        emailjs.send(EMAILJS_SERVICE_ID!, EMAILJS_TEMPLATE_ID!, {
            request_name: name,
            request_email: email,
            reason: course,
            message: message,
        }, EMAILJS_PUBLIC_KEY).then((result) => {
            enqueueSnackbar('Email sent successfully', {variant: 'success'});
            track('Email Sent', {course: course});
            // Send confirmation email
            sendConfirmation();
        }, (error) => {
            track('Email Error', {course: course})
            enqueueSnackbar('Error sending email', {variant: 'error'});
            setIsSubmitting(false); // Re-enable the button
        });
    };

    if (!envLoaded) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box className="contactBox">
            <Typography variant="h4" align="center" mb={2}>
                {translations.contactPage.pageTitle}
            </Typography>
            <form onSubmit={handleSubmit} className="contactForm">
                <ThemeProvider theme={theme}>
                    <TextField
                        value={course}
                        onChange={(e) => setCourse(e.target.value)}
                        select // tell TextField to render select
                        fullWidth
                        required
                        label={translations.contactPage.subjectLabel}
                    >
                        {translations.coursePage.courses.map(course => {
                            return (<MenuItem key={course.id} value={course.id}>{course.name}</MenuItem>);
                        })}
                        <MenuItem key="default" value={translations.contactPage.selectDefaultValue}>{translations.contactPage.selectDefaultValue}</MenuItem>
                    </TextField>
                    <TextField
                        fullWidth
                        label={translations.contactPage.nameLabel}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    <TextField
                        fullWidth
                        label={translations.contactPage.emailLabel}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        type="email"
                    />
                    <TextField
                        fullWidth
                        label={translations.contactPage.messageLabel}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                        multiline
                        rows={4}
                    />
                </ThemeProvider>
                <Button variant="contained" type="submit" className="contactButton" disabled={isSubmitting}>
                    {isSubmitting ? <CircularProgress size={24} /> : translations.contactPage.submitButtonLabel}
                </Button>
            </form>
        </Box>
    )
}
export default Contact;