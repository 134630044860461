import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography
} from "@mui/material";
import * as React from "react";
import translations from '../translations.json';
import {useNavigate} from "react-router-dom";

export const Courses = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-around',
            flexWrap: 'wrap',
            gap: "1rem",
            margin: '1rem'
        }}>
            {translations.coursePage.courses.map((course, index) => (
                <Card key={index} sx={{ display: 'flex', flexDirection: 'column', marginBottom: 2, backgroundColor: '#a9927d', color: 'white', width: { xs: '100%', md: '45%' } }}>
                  <CardHeader title={course.name} />
                  <CardContent sx={{ flexGrow: 1 }}>
                    {
                      course.prices ?
                          course.prices.map((priceEntry: {duration: string, price: string}, index: number) => (
                              <Typography fontWeight="bold" key={index}>{priceEntry.duration + ": " + priceEntry.price}</Typography>
                          ))
                          :
                          <Typography fontWeight="bold">Preis auf Anfrage</Typography>
                    }
                    <br/>
                    <Typography>{course.description}</Typography> {/* Kursbeschreibung angezeigt */}
                  </CardContent>
                  <CardActions sx={{ justifyContent: 'center' }}> {/* Button zentriert */}
                    <Button size="small" color="primary" variant="contained" onClick={() => navigate(`/contact?course=${course.id}`)}> {/* Button-Variante auf "contained" gesetzt */}
                      Buchen
                    </Button>
                  </CardActions>
                </Card>
            ))}
            <Alert severity="info" sx={{ '.MuiAlert-icon': {alignItems: "center"},width: { xs: 'unset', md: '100%' } }}>
                {translations.coursePage.distanceRateText}
                <br />
                {translations.coursePage.paymentInfoText}
            </Alert>
        </Box>
    );
}
export default Courses;