import React from 'react';
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import ErsteErfahrungen from "../images/blog/my-way/erste-erfahrungen.jpg";
import ErstesFreiesReiten from "../images/blog/my-way/erstes-freies-reiten.jpg";
import PonyRennen from "../images/blog/my-way/pony-rennen.jpg";
import ErsteBegegnungMitRodi from "../images/blog/my-way/erste-begegnung-mit-rodi.jpg";
import Queijo from "../images/blog/my-way/queijo.jpg";
import translations from "../translations.json";
import {Helmet} from "react-helmet-async";

export const MyWay = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box sx={{padding: 2}}>
            <Helmet>
                <title>{translations.galleryPage.pageTitle}</title>
                <meta name="description" content={translations.blogPage.myWay.description}/>
                <meta property="og:title" content={translations.blogPage.myWay.pageTitle}/>
                <meta property="og:description" content={translations.blogPage.myWay.description}/>
                <meta property="og:url" content="https://www.frei-arbeit.ch/blog/my-way"/>
                <meta property="og:type" content="website"/>
                <link rel="canonical" href="https://www.frei-arbeit.ch/blog/my-way"/>
            </Helmet>
            <Typography textAlign="center" variant="h3">
                Mein Weg
            </Typography>
            <Box sx={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column', my: 2}}>
                <Typography variant="body1" gutterBottom>
                    Ich hatte das Privileg in einer Familie aufzuwachsen in der Pferde eine grosse Rolle spielten.
                    Durch meine Mutter hatte ich kaum war ich auf der Welt schon Kontakt zu Pferden. Als ich gerade
                    mal zwei Jahre alt war wollte ich unbedingt reiten lernen.
                </Typography>
                <Box display="flex" flexDirection={isSmallScreen ? 'column' : 'row'} flexWrap="nowrap" gap="1rem" alignItems={isSmallScreen ? 'center' : 'unset'} justifyContent="space-between">
                    <Box component="img"
                         src={ErsteErfahrungen}
                         alt="Erste Erfahrungen"
                         sx={{maxWidth: isSmallScreen ? '100%' : '48%'}}
                    />
                    <Box component="img"
                         src={ErstesFreiesReiten}
                         alt="Erste freies Reiten"
                         sx={{maxWidth: isSmallScreen ? '100%' : '48%'}}
                    />
                </Box>
            </Box>
            <Box sx={{display: "flex", flexDirection: isSmallScreen ? 'column' : 'row', alignItems: 'center', my: 2}}>
                <Typography variant="body1" gutterBottom>
                    Ab sechs Jahren freute ich mich sehr über meine ersten Reitbeteiligungen auf verschiedenen Ponies
                    und wollte noch mehr lernen.
                    Auch einen Abstecher in den Pony-Rennsport brachte mir wertvolle Erfahrungen und Erkenntnisse.
                </Typography>
                <Box component="img"
                     src={PonyRennen}
                     alt="Pony Rennen"
                     sx={{maxWidth: isSmallScreen ? '100%' : '48%', marginLeft: isSmallScreen ? 0 : 2}}
                />
            </Box>
            {
                isSmallScreen ?
                    <Box sx={{display: "flex", flexDirection: 'column',alignItems: 'center', my: 2}}>
                        <Typography variant="body1" gutterBottom>
                            Meinen bislang schönsten Tag erlebte ich aber mit neun Jahren als meine Mutter mir mein erstes eigenes
                            Pferd Rodney schenkte.
                            Wir beide mussten viel Kritik einstecken denn Rodney war ein Jungpferd aus dem Tierschutz komplett roh
                            und sicher auch ein
                            Stück weit traumatisiert. Somit sammelte ich schon sehr früh erste Erfahrungen mit der
                            Jungpferde-Ausbildung.
                        </Typography>
                        <Box component="img"
                             src={ErsteBegegnungMitRodi}
                             alt="Erste Begegnung mit Rodi"
                             sx={{maxWidth: '100%'}}
                        />
                    </Box>
                    : <Box sx={{display: "flex", alignItems: 'center', my: 2}}>
                        <Box component="img"
                             src={ErsteBegegnungMitRodi}
                             alt="Erste Begegnung mit Rodi"
                             sx={{maxWidth: '48%', marginRight: 2}}
                        />
                        <Typography variant="body1">
                            Meinen bislang schönsten Tag erlebte ich aber mit neun Jahren als meine Mutter mir mein erstes eigenes
                            Pferd Rodney schenkte.
                            Wir beide mussten viel Kritik einstecken denn Rodney war ein Jungpferd aus dem Tierschutz komplett roh
                            und sicher auch ein
                            Stück weit traumatisiert. Somit sammelte ich schon sehr früh erste Erfahrungen mit der
                            Jungpferde-Ausbildung.
                        </Typography>
                    </Box>
            }
            <Box sx={{display: "flex", flexDirection: isSmallScreen ? 'column' : 'row', alignItems: 'center', my: 2}}>
                <Typography variant="body1" gutterBottom>
                    Ich wollte mich stets weiterentwickeln um noch besser und genauer zu verstehen. Es war manchmal schon
                    etwas merkwürdig als
                    einziges Kind in Kursen mitzureiten die sonst nur Erwachsene besuchten. Unter meinen Lehrern waren
                    namhafte Trainer wie
                    Manolo Mendez Desmond O‘Brien und Arien Aguilar. Mir gefielen Menschen welche die feine Art der
                    Kommunikation der Pferde
                    wahrnahmen und verstanden. Ich war fasziniert davon und entschlossen diesen Weg weiterzuverfolgen.
                    Im Sommer 2024 schloss ich meine obligatorische Schulzeit ab und fuhr ein Jahr nach Portugal zu Arien
                    Aguilar und Tanja Riedinger.
                    Dort absolvierte ich ein Praktikum wobei mein Pferd Rodney natürlich mit von der Partie war.
                    In den regelmässigen Unterrichtsstunden bei Tanja und Arien habe ich viel gelernt. Nebst dem dass ich
                    mich mit meinem Pferd Rodney
                    weiterentwickeln konnte habe ich auch sehr viel über die Arbeit mit den Junghengsten und deren
                    Ausbildung zum Reitpferd gelernt.
                    Und dann kam der Valentinstag in Portugal an dem ich meinen selbst ausgebildeten Junghengst Queijo mein
                    Eigen nennen durfte. Es war
                    ein Traum!
                </Typography>
                <Box component="img"
                     src={Queijo}
                     alt="Queijo"
                     sx={{maxWidth: isSmallScreen ? '100%' : '48%'}}
                />
            </Box>
            <Typography variant="body1" gutterBottom>
                Ich durfte in all den Jahren so viel Gutes von tollen Trainern lernen und nun ist es mein Ziel mein
                angesammeltes Wissen auch weiterzugeben
                und den Menschen zu einer feinen Kommunikation mit ihren Pferden zu verhelfen und die Pferdewelt ein
                Stück weit verständnisvoller zu machen.
            </Typography>
        </Box>
    );
}

export default MyWay;
