import translations from "../translations.json";
import {Helmet} from "react-helmet-async";
import React from "react";
import RatingComponent from "../components/RatingComponent";

export const Rezensionen = () => {
    return (
        <div>
            <Helmet>
                <title>{translations.rezensionenPage.pageTitle}</title>
                <meta name="description" content={translations.rezensionenPage.description}/>
                <meta property="og:title" content={translations.rezensionenPage.pageTitle}/>
                <meta property="og:description" content={translations.rezensionenPage.description}/>
                <meta property="og:url" content="https://www.frei-arbeit.ch/rezensionen"/>
                <meta property="og:type" content="website"/>
                <link rel="canonical" href="https://www.frei-arbeit.ch/rezensionen"/>
            </Helmet>
            <RatingComponent/>
        </div>
    );
}