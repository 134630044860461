// src/data/service.ts
export interface Review {
  id?: string; // Optional MongoDB ObjectId
  name: string;
  rating: number;
  comment: string;
}

export const getReviews = async (): Promise<Review[]> => {
  try {
    const response = await fetch("https://nadine-backend.vercel.app/reviews", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  } catch (error) {
    console.error("Error:", error);
    return [];
  }
};

export const saveReview = async (review: Review) => {
  try {
    const response = await fetch("https://nadine-backend.vercel.app/reviews", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(review),
    });
    return await response.json();
  } catch (error) {
    console.error("Error:", error);
    return { error: "An error occurred" };
  }
};