// src/components/RatingComponent.tsx
import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import Rating from '@mui/material/Rating';
import {getReviews, Review, saveReview} from "../data/service";

const RatingComponent: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [reviews, setReviews] = useState<Review[]>([]);
  const [averageRating, setAverageRating] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingReviews, setLoadingReviews] = useState(true);

  useEffect(() => {
    loadReviews();
  }, []);

  useEffect(() => {
    setRating(0);
    setName('');
    setComment('');
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const newReview: Review = { name, rating, comment };
    const result = await saveReviews(newReview);
    setLoading(false);
    if (result) {
      handleClose();
    }
  };

  const calculateAverageRating = (reviews: Review[]) => {
    const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
    const average = reviews.length ? (totalRating / reviews.length).toFixed(2) : "0";
    setAverageRating(parseFloat(average));
  };

  const saveReviews = async (review: Review) => {
    const response = await saveReview(review);
    if (!response.error) {
      loadReviews();
      return true;
    }
    return false;
  };

  const loadReviews = async () => {
    setLoadingReviews(true);
    const loadedReviews: Review[] = await getReviews();
    setReviews(loadedReviews);
    calculateAverageRating(loadedReviews);
    setLoadingReviews(false);
  };

  return (
      <Box display="flex" flexDirection="column" alignItems="center" maxHeight="100vh">
        <Box display="flex" flexDirection="column" width={{ xs: '90%', md: '50%' }} maxHeight="85vh" sx={{ mt: 5 }}>
          <Typography variant="h3" textAlign="center" marginBottom={3}>Bewerte mich</Typography>

          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            Bewertung erfassen
          </Button>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Typography variant="h6" sx={{ mr: 1 }}>
              Durchschnittsbewertung:
            </Typography>
            <Rating value={averageRating} readOnly precision={0.1} />
          </Box>
          <List sx={{ flexGrow: 1, overflow: 'auto', mt: 2 }}>
            {loadingReviews ? (
                <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
                  <CircularProgress />
                </Box>
            ) : (
                reviews.map((review, index) => (
                    <ListItem key={index}>
                      <Paper sx={{ padding: 2, width: '100%' }}>
                        <ListItemText
                            secondary={
                              <Box display="flex" flexDirection="column" gap={1}>
                                <Typography variant="h6" fontWeight="bold">{review.name}</Typography>
                                <Rating value={review.rating} readOnly />
                                <Typography component="span">{review.comment}</Typography>
                              </Box>
                            }
                        />
                      </Paper>
                    </ListItem>
                ))
            )}
          </List>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Submit your Rating</DialogTitle>
            <DialogContent>
              <form onSubmit={handleSubmit}>
                <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    margin="normal"
                    required
                />
                <Typography component="legend">Rating</Typography>
                <Rating
                    name="rating"
                    value={rating}
                    onChange={(event, newValue) => setRating(newValue ? newValue : 0)}
                />
                <TextField
                    label="Comment"
                    variant="outlined"
                    fullWidth
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    margin="normal"
                    multiline
                    rows={4}
                />
                <DialogActions>
                  <Button onClick={handleClose} color="primary" disabled={loading}>
                    Cancel
                  </Button>
                  <Button type="submit" color="primary" variant="contained" disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : "Submit"}
                  </Button>
                </DialogActions>
              </form>
            </DialogContent>
          </Dialog>
        </Box>
      </Box>
  );
};

export default RatingComponent;