import MenuIcon from "@mui/icons-material/Menu";
import {Box, IconButton, useMediaQuery, useTheme} from "@mui/material";

type BurgerIconProps = {
  onClick: () => void;
}

export const BurgerMenuButton = ({onClick}: BurgerIconProps) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md')); // Adjust breakpoint as needed

  return (
      <Box sx={{
        width: isLargeScreen ? '33.33%' : '25%'
      }}>
        <IconButton
            onClick={onClick}
            sx={{
              margin: '7px', // Add some margin
              width: '50px', // Set the width
              height: '50px', // Set the height
              backgroundColor: '#D9D9D9', // Set the background color
              borderRadius: '20px', // Make the button square
              '&:hover': {
                backgroundColor: '#B3B3B3', // Change the background color on hover
              }
            }}
        >
          <MenuIcon sx={{color: "#000000"}}/>
        </IconButton>
      </Box>
  );
}

export default BurgerMenuButton;