import {Box, Link, Typography} from "@mui/material";
import {useState} from "react";
import BurgerMenuButton from "./BurgerMenuButton";
import LogoIcon from "../images/icons/LogoIcon";
import './PageHeader.css';

export const PageHeader = () => {
    const menuItems = [
        {
            name: 'Home',
            link: '/'
        },
        {
            name: 'Über mich',
            link: '/me'
        },
        {
            name: 'Angebote',
            link: '/offers'
        },
        {
            name: 'Galerie',
            link: '/gallery'
        },
        {
            name: 'Kontakt',
            link: '/contact'
        },
        {
            name: 'Rezensionen',
            link: '/rezensionen'
        },
        {
            name: 'Blog',
            subItems: [
                {
                    name: 'Mein Weg',
                    link: '/blog/my-way'
                }
            ]
        }
    ];

    const [isMenuOpen, setMenuOpen] = useState(false);
    const [activeSubItems, setActiveSubItems] = useState<number>();

    const handleMenuToggle = () => {
        setMenuOpen(!isMenuOpen);
        if (!isMenuOpen) {
            setActiveSubItems(undefined);
        }
    };

    const handleSubItemsToggle = (index: number) => {
        if (activeSubItems === index) {
            setActiveSubItems(undefined);
        } else {
            setActiveSubItems(index);
        }
    };

    return (
        <>
            <Box className="page-header">
                <BurgerMenuButton onClick={handleMenuToggle} />
                <Link href="/" className="logo-link">
                    <Box className="logo-box">
                        <LogoIcon />
                        <Box>
                        <Typography textAlign="center" className="logo-text" variant="h1" fontWeight="bold" color="#000000">
                            FreiArbeit
                        </Typography>
                        <Typography textAlign="center" className="logo-text" variant="h1" fontWeight="bold" color="#000000">
                            Nadine Frei
                        </Typography>
                        </Box>
                    </Box>
                </Link>
                <Box sx={{ width: "33.33%" }} />{/* Hier fügen wir ein leeres Box-Element hinzu, um den Abstand zu den Seiten zu vergrößern */}
            </Box>
            {isMenuOpen && (
                <Box sx={{
                    position: "fixed",
                    top: "4rem",
                    width: "100%",
                    backgroundColor: "#483520FF",
                    zIndex: 1000
                }} className="menu-animation">
                    {menuItems.map((item, index) => (
                        <Box key={index} className="menu-item">
                            {item.subItems ? (
                                <Typography variant="h6" className="menu-item-text"
                                            onClick={() => handleSubItemsToggle(index)}>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: "10px", marginRight: {sm: "10px", xs: "unset" }}}>
                                        {item.name}
                                        <span className={`arrow ${activeSubItems === index ? 'open' : ''}`}>⬇︎</span>
                                    </Box>
                                </Typography>
                            ) : (
                                <Link sx={{textDecoration: "none",}} href={item.link} onClick={() => setMenuOpen(false)}>
                                    <Typography variant="h6" className="menu-item-text">
                                        <Box sx={{ marginLeft: "10px", marginRight: {sm: "10px", xs: "unset" }}}>
                                            {item.name}
                                        </Box>
                                    </Typography>
                                </Link>
                            )}
                            {item.subItems && activeSubItems === index && (
                                item.subItems.map((subItem, subIndex) => (
                                    <li key={subIndex} style={{ marginLeft: "2rem" }}>
                                        <Link
                                            sx={{textDecoration: "none",}}
                                            href={subItem.link}
                                            className="menu-sub-item"
                                            onClick={() => setMenuOpen(false)}
                                        >
                                            <Typography variant="h6">
                                                <Box sx={{ marginLeft: { sm: "7px", xs: "unset" } }}>{subItem.name}</Box>
                                            </Typography>
                                        </Link>
                                    </li>
                                ))
                            )}
                        </Box>
                    ))}
                </Box>
            )}
        </>
    );
}

export default PageHeader;
