import {Box} from "@mui/material";
import Contact from "../components/contact/Contact";
import {SnackbarProvider} from "notistack";
import {Helmet} from "react-helmet-async";
import translations from "../translations.json";

export const ContactPage = () => {

  return (
      <>
          <Helmet>
              <title>{translations.contactPage.pageTitle}</title>
              <meta name="description" content={translations.contactPage.description}/>
              <meta property="og:title" content={translations.contactPage.pageTitle}/>
              <meta property="og:description" content={translations.contactPage.description}/>
              <meta property="og:url" content="https://www.frei-arbeit.ch/contact"/>
              <meta property="og:type" content="website"/>
              <link rel="canonical" href="https://www.frei-arbeit.ch/contact"/>
          </Helmet>
        <SnackbarProvider maxSnack={2} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
          <Box>
            <Contact/>
          </Box>
        </SnackbarProvider>
      </>
  )
}
export default ContactPage;