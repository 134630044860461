import React, {useState} from 'react';
import './App.css';
import {createBrowserRouter, RouteObject, RouterProvider} from "react-router-dom";
import Home from "./pages/Home";
import ContactPage from "./pages/ContactPage";
import {GalleryPage} from "./pages/GalleryPage";
import CoursesPage from "./pages/CoursesPage";
import {Box} from "@mui/material";
import PageHeader from "./components/PageHeader";
import {Analytics} from "@vercel/analytics/react";
import {SpeedInsights} from "@vercel/speed-insights/react";
import AboutMe from "./pages/AboutMe";
import {Helmet} from "react-helmet-async";
import translations from "./translations.json";
import MyWay from "./pages/MyWay";
import {Rezensionen} from "./pages/Rezensionen";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/contact",
    element: <ContactPage />,
  },
  {
    path: "/gallery",
    element: <GalleryPage />,
  },
  {
    path: "/offers",
    element: <CoursesPage />,
  },
  {
    path: "/me",
    element: <AboutMe />,
  },
  {
    path: "/blog/my-way",
    element: <MyWay />,
  },
  {
    path: "/rezensionen",
    element: <Rezensionen/>
  }
];

function App() {
  const [open, setOpen] = useState(true);
  const router = createBrowserRouter(routes);

  return (
      <>
        <Helmet>
          <title>{translations.homePage.pageTitle}</title>
          <meta name="description" content={translations.homePage.description}/>
          <meta property="og:title" content={translations.homePage.pageTitle}/>
          <meta property="og:description" content={translations.homePage.description}/>
          <meta property="og:url" content="https://www.frei-arbeit.ch"/>
          <meta property="og:type" content="website"/>
          <link rel="canonical" href="https://www.frei-arbeit.ch"/>
        </Helmet>
          <PageHeader />
          <Box sx={{ paddingTop: '4rem' }}>
            <RouterProvider router={router}/>
          </Box>
        <Analytics />
        <SpeedInsights />
      </>
  );
}

export default App;
