export const IconName = () => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0,0,256,256"
         width="200px" height="200px">
        <g transform="translate(-23.04,-23.04) scale(1.18,1.18)">
            <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt"
               strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0"
               fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none">
                <g transform="translate(0,21.33333) scale(5.33333,5.33333)">
                    <path d="M40,12h-18l-4,-4h-10c-2.2,0 -4,1.8 -4,4v8h40v-4c0,-2.2 -1.8,-4 -4,-4z" fill="#1976d2"/>
                    <path
                        d="M40,12h-32c-2.2,0 -4,1.8 -4,4v20c0,2.2 1.8,4 4,4h32c2.2,0 4,-1.8 4,-4v-20c0,-2.2 -1.8,-4 -4,-4z"
                        fill="#2196f3"/>
                </g>
            </g>
        </g>
    </svg>
);

export default IconName;