import Gallery from "../components/gallery/Gallery";
import {Box} from "@mui/material";
import {Helmet} from "react-helmet-async";
import translations from "../translations.json";

export const GalleryPage = () => {
    return (
        <Box>
          <Helmet>
            <title>{translations.galleryPage.pageTitle}</title>
            <meta name="description" content={translations.galleryPage.description}/>
            <meta property="og:title" content={translations.galleryPage.pageTitle}/>
            <meta property="og:description" content={translations.galleryPage.description}/>
            <meta property="og:url" content="https://www.frei-arbeit.ch/gallery"/>
            <meta property="og:type" content="website"/>
            <link rel="canonical" href="https://www.frei-arbeit.ch/gallery"/>
          </Helmet>
          <Gallery/>
        </Box>
    )
}