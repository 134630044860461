import React from 'react';
import {Box, Typography, useMediaQuery, useTheme} from '@mui/material';
import translations from '../translations.json';
import './styles/home.css';
import {renderTextWithLineBreaks} from "../data/helpers"; // Import the CSS file

export const Home = () => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl')); // xl is desktop
    const {
        pageTitle,
        quote,
        quoteAuthor
    } = translations.homePage;
    return (
        <>
            {isDesktop ? (
                <Box className="fullScreenBackground">
                    <Box className="absoluteCenterLeft">
                        <Typography variant="h3" className="title">
                            {pageTitle}
                        </Typography>
                    </Box>
                    <Box className="absoluteCenterRight">
                        <Typography variant="h4" className="quote">
                            {renderTextWithLineBreaks(quote)}
                        </Typography>
                        <Typography variant="h5" className="quoteAuthor">
                            {quoteAuthor}
                        </Typography>
                    </Box>
                </Box>
            ) : (
                <>
                    <Box className="container" />
                    <Typography  textAlign="center" variant="h3" className="title">
                        {pageTitle}
                    </Typography>
                    <Box className="textContainer">
                        <Typography variant="h4" color="white" fontWeight="bold" textAlign="center">
                            {renderTextWithLineBreaks(quote)}
                        </Typography>
                        <Typography variant="h5" color="white" textAlign="center">
                            {quoteAuthor}
                        </Typography>
                    </Box>
                </>
            )}
        </>
    );
};

export default Home;