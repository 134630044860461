import Courses from "../components/Courses";
import translations from "../translations.json";
import {Helmet} from "react-helmet-async";


export const CoursesPage = () => {

  return (
      <>
        <Helmet>
          <title>{translations.coursePage.pageTitle}</title>
          <meta name="description" content={translations.coursePage.description}/>
          <meta property="og:title" content={translations.coursePage.pageTitle}/>
          <meta property="og:description" content={translations.coursePage.description}/>
          <meta property="og:url" content="https://www.frei-arbeit.ch/courses"/>
          <meta property="og:type" content="website"/>
          <link rel="canonical" href="https://www.frei-arbeit.ch/courses"/>
        </Helmet>
        <Courses/>
      </>
  )
}
export default CoursesPage;