// src/assets/images/helpers.ts

import {Typography} from "@mui/material";

export interface FileTree {
  [key: string]: { path: string } | FileTree;
}

// Helper function to create a nested structure from flat file paths
export const createFileTree = (files: Record<string, string>): FileTree => {
  const fileTree: FileTree = {};

  Object.keys(files).forEach((path) => {
    const parts = path.split('/');
    let current = fileTree;

    parts.forEach((part, index) => {
      if (!current[part]) {
        if (index === parts.length - 1) {
          // Adjust the path to match the new file structure
          const imagePath = `/images/gallery/${path}`;
          current[part] = {path: imagePath};
        } else {
          current[part] = {};
        }
      }
      current = current[part] as FileTree;
    });
  });

  return fileTree;
};
// Assuming renderTextWithLineBreaks function splits text by line breaks and returns a list of Typography components
export const renderTextWithLineBreaks = (text: string) => {
  return text.split('\n').map((line, index) => (
      <Typography key={index}>
        {line}
      </Typography>
  ));
};
